<template>
  <div>
    <v-tabs v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-2">
        업데이트로그
      </v-tab>
      <v-tab href="#tab-3">
        스케줄러
      </v-tab>
      <v-tab href="#tab-4">
        차량정보
      </v-tab>

    </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item value="tab-2">
            <List />
        </v-tab-item>
        <v-tab-item value="tab-3">
            <Task />
        </v-tab-item>
        <v-tab-item value="tab-4">
            <CarList />
        </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import List from './List'
import Task from './Task'
import CarList from './CarList'
  export default {
    name: 'HelloWorld',
    components: {
      List,
      Task,
      CarList


    },
    data () {
      return {
        
        tab: null,
        
      }
    },
  }
</script>
