<template>

    <div>
            <el-cascader-panel
            style="width:100%"
            v-model="value"
            :options="options"
            :props="{ expandTrigger: 'hover' }"
            placeholder="차량정보를 직접입력하거나 선택하세요"
            @change="handleChange" clearable filterable></el-cascader-panel>
  </div>
</template>

<script>
  import cardata from '@/api/category.json'
  export default {
    name: 'CarCategory',
    data() {
      return {
        options: cardata,
        value: [],
        
      };
    },
    methods: {
      handleChange(value) {
        console.log(value);
        console.log('카테고리 변경', value)
        const lastItem = value[value.length - 1]
        const obj = JSON.parse(lastItem)
        console.log('카테고리 변경 lastItem', obj)
        this.$store.dispatch('carOptions',obj)

      }
    }
  };
</script>